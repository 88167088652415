.img { 
	width: 100%;
	height: auto;
	object-fit: cover;
}

.content-parent {
	color: var(--sky-blue);
	border-color: var(--sky-blue);
}

.main-content {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 3rem 2rem;
	padding: 0;
	list-style: none;
}

.widget-title {
	font-size: 1.55rem;
	font-weight: 600 !important;
}

.divisor {
	border-top: 1px solid var(--shadow-gray) !important;
	margin: 0.5rem 0rem !important;
}

.image {
	width: 100%;
}

.block-content {
	width: 100%;

	overflow: hidden;
}

.block-content h5{
    font-size: 1.2rem;
    font-family: Roboto, sans-serif;
    line-height: 1.4rem;
    font-weight: 500;
    letter-spacing: -.4px;
    margin-top: .3rem;
    color: var(--dark-black);
}

.sponsored a {
    position: relative;
}

.sponsored .badge{
    display: flex;
    align-items: center;
    position: absolute;
    -moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: .5rem;
    background: var(--sponsored);
    opacity: .7;
    color: var(--white);
    padding: .4rem .5rem .4rem .5rem;
    border-radius: 4px;
    font-size: .60rem;
    text-transform: uppercase;
    letter-spacing: 0px;
	font-weight: normal;
}


@media (max-width: 980px) {
	.main-content {
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
	}

	.hide-md {
		display: none;
	}
}

@media (max-width: 768px) {
	.main-content {
		grid-template-columns: repeat(2, 1fr);
	}
	.block-content h5 {
		margin: 0.4rem 0 1rem 0;
		padding: 0 5px 0 5px;
	}
}

@media (max-width: 480px) {
	.main-content {
		grid-template-columns: repeat(1, 1fr);
	}

	.hide-md {
		display: block;
	}

	.hide-sm {
		display: none;
	}
}