.container-loop {
    width: 100%;
    width: 355px;
    margin-left: -28px;
}
@media (max-width: 768px) {
    .container-loop {
        width: 100%;
        height: auto;
        max-width: 100%;
        margin-left: 0;
    }
}