.envivo{
    display: flex;
    align-items: center;
    position: absolute;
    transform: translateY(-140%);
    -moz-transform: translateY(-140%);
    -ms-transform: translateY(-140%);
    -o-transform: translateY(-140%);
    -webkit-transform: translateY(-140%);
    left: 0.5rem;
    background: var(--envivo);
    opacity: .9;
    color: var(--white);
    padding: .40rem .5rem .45rem 1.4rem;
    border-radius: 4px;
    font-size: .67rem;
    text-transform: uppercase;
}
.envivo::before {
    position: absolute;
    top: 49%;
    left: 0.4575rem;
    display: inline-flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
    content: '';
    background-color: var(--white);
    border-radius: 100%;
    transform: translate3d(-50%, -50%, 0);
    backface-visibility: hidden;
    transform-style: preserve-3d;
    left: 0.7rem;
}